<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card color="tertiary" class="eleavtion-1">
          <v-container>
            <v-row>
              <v-col cols="12" class="body-2">
                <v-icon left small color="primary">fas fa-tag</v-icon>Ganhe
                descontos em <span class="font-weight-medium">consultas</span> e
                <span class="font-weight-medium">medicamentos</span> pagando com
                código QR
              </v-col>
            </v-row>
            <v-divider />
            <v-row>
              <v-col cols="4">
                <span class="body-2 text-truncate">{{ $util.mercadoPago.descricao }}</span>
                <v-card>
                  <a
                    :href="$util.getUrlApp($util.mercadoPago)"
                    :style="{ 'text-decoration': 'none' }"
                  >
                    <v-img :src="'/img/logos/' + $util.mercadoPago.img"></v-img>
                  </a>
                </v-card>
              </v-col>

              <v-col
                cols="4"
                pb-4
                @click="
                  $util.link('fullScreenImg', {
                    title: 'Pill Pay | Escanear Código QR',
                    url: '/img/scanQRCode.jpg'
                  })
                "
              >
                <span class="body-2">Pill Pay</span>
                <v-card>
                  <v-img :src="'/img/logos/logoPillPay.jpg'"></v-img>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card color="tertiary" class="eleavtion-1">
          <v-container>
            <v-row>
              <v-col cols="12" class="body-2">
                <v-icon left small color="primary">fas fa-tag</v-icon>Ofertas
                exclusivas nas farmácias credenciadas
              </v-col>
            </v-row>
            <v-divider />
            <v-row>
              <template v-for="(item, index) in items">
                <v-col cols="4" :key="'item' + index">
                  <span class="body-2">{{ item.nome }}</span>
                  <v-card class="elevation-4">
                    <v-img :src="'/img/logos/' + item.logo"></v-img>
                  </v-card>
                  <span class="body-2">
                    Até
                    <span class="font-weight-bold">R$ {{ item.valor }}</span>
                  </span>
                </v-col>
              </template>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card color="tertiary" class="eleavtion-1">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-icon left small color="primary"
                  >fas fa-hand-holding-usd</v-icon
                >Reembolso e Coparticipação de acordo com a política da sua
                Empresa
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          nome: "Drogasil",
          logo: "logoDrogasil.jpg",
          valor: "50,00"
        },
        {
          nome: "Drogaria SP",
          logo: "logoDrogaSP.jpg",
          valor: "40,00"
        },
        {
          nome: "Ultrafarma",
          logo: "logoUltrafarma.jpg",
          valor: "60,00"
        },
        {
          nome: "Droga Raia",
          logo: "logoDrogaraia.jpg",
          valor: "30,00"
        },
        {
          nome: "Onofre",
          logo: "logoOnofre.jpg",
          valor: "20,00"
        },
        {
          nome: "Droga Verde",
          logo: "logoDrogaVerde.jpg",
          valor: "80,00"
        }
      ]
    };
  }
};
</script>

<style></style>
